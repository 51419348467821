.loader {
  border: 6px solid white;
  border-top: 6px solid darkgreen;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 0.75s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}